import InformationCard from "@shared/InformationCard/InformationCard.vue"
import FocusedInformationCard from "@shared/FocusedInformationCard/FocusedInformationCard.vue"
import FilterChipsContainer from "@shared/FilterChipsContainer/FilterChipsContainer.vue"
import NoResults from "@shared/NoResults/NoResults.vue"
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import config from '@/helpers/config'
import { bookmarkImage, unBookmarkImage } from '@services/Images.api'
import { getUserData } from '@services/User.api'
import { removeByAttr, getEntitiesList, removeUndefined } from '@mixins/helpers'
import InformationCardMixin from '@mixins/informationCardMixin' 
import searchMixin from '@mixins/searchMixin' 

export default {
    name:"Images",
    mixins: [InformationCardMixin, searchMixin],

    components: {
        InformationCard,
        FocusedInformationCard,
        FilterChipsContainer,
        NoResults
    },

    data() {
        return {
            activeImage: null,
            isActiveImageBookmarked: false,
            bookmarkedImages: [],

            images: [],
            apiUrl: config.apiUrl,
            currentPage: 1,
            totalPages: 0,
            query: {
                pageSize: '',
                pageNumber: '',
                order: '',
                sortBy: ''
            },
            currentPageCountStart: 1,
            currentPageCountEnd: 1,
            filterChipsData: {},
            showFilterChipsContainer: false,
            hasNoResults: false,
            searchValue: "",
            isBookmarkLoading: false
        }
    },

    // props: {
    //     value: {
    //         type: String
    //     }
    // },

    async created() {
        if (this.isLoggedIn) {
            await getUserData(this.user.id).then(
                response => {
                    this.bookmarkedImages = response.data.payload.images
                }, err => {
                    console.log('err ', err);
                }
            )
        }
    },

    mounted() {
        this.currentPage = parseInt(this.$route.query.pageNumber)
        this.searchValue = this.$route.query.value

        var query = {...this.getBaseQuery}
        delete query['reloadId']
        
        if (this.canSearchWithNoValue(query)) {
            var formattedQuery = this.getFormattedQuery(query)
            this.fetchImages(formattedQuery)
            this.SET_SEARCH_VALUE(this.searchValue)

            this.filterChipsData = JSON.parse(JSON.stringify(this.getBaseQuery));
            this.setShowFilterChipsContainer()
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    },

    computed: {
        ...mapGetters('images', ['getSearchResultImages', 'getImagesGlobalCount', 'getImagesLocalCount' ]),
        ...mapGetters('search',['getBaseQuery']),
        ...mapGetters('auth', ['isLoggedIn']),
        ...mapState('auth', ['user']),
        ...mapState('loaders',['isPageLoading'])
    },

    methods: {
        ...mapActions('images', ['fetchSearchResultImages']),
        ...mapMutations('search', ['SET_SEARCH_VALUE']),
        ...mapMutations('loaders', ['SET_IS_PAGE_LOADING']),
        ...mapMutations('search', ['SET_BASE_QUERY', 'RESET_PAGE_NUMBER']),
        ...mapMutations('notification', ['notify']),

        async fetchImages(query) {
            this.SET_IS_PAGE_LOADING(true)
            var data = {
                value: this.searchValue,
                query: query
            }

            await this.fetchSearchResultImages(data);
            var imagesArray = this.getSearchResultImages
            this.setImagesArray(imagesArray)
            this.setHasNoResults()
            this.totalPages = Math.ceil(this.getImagesGlobalCount/this.getBaseQuery.pageSize)
            this.currentPageCountStart = parseInt(this.getBaseQuery.pageSize) * parseInt(this.getBaseQuery.pageNumber) - parseInt(this.getBaseQuery.pageSize) + 1
            this.currentPageCountEnd = parseInt(this.getBaseQuery.pageSize) * parseInt(this.getBaseQuery.pageNumber) - parseInt(this.getBaseQuery.pageSize) + parseInt(this.getImagesLocalCount)

            if (this.$route.hash) {
                this.activeImage = this.images.find(image => image.id == this.$route.hash.substring(1)) 
            }
           
            this.SET_IS_PAGE_LOADING(false)
        },

        setReloadId() {
            var query = JSON.parse(JSON.stringify(this.getBaseQuery));
            let reloadId = Date.now().toString(36) + Math.random().toString(36).substring(2);
            query["reloadId"] = reloadId

            this.SET_BASE_QUERY(query)
        },

        getFormattedQuery(query) {
            var formattedQuery = "?"

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    if (query[key]) {
                        if (Array.isArray(query[key])) {
                            for (let i = 0; i < query[key].length; i++) {
                                formattedQuery += key + "=" + query[key][i] + '&'
                            }
                        } else {
                            formattedQuery += key + "=" + query[key] + '&'
                        }
                    }
                }
            }

            formattedQuery = formattedQuery.substring(0, formattedQuery.length - 1);
            return formattedQuery
        },

        setImagesArray(imagesArray) {
            this.images = imagesArray.map(image => {
                return {
                    id: image.id,
                    isSafirContent: false,
                    src: this.apiUrl + '/entities/images/raw/' + image.fileName + '/400',
                    lazysrc: this.apiUrl + '/entities/images/raw/' + image.fileName + '/100',
                    title: image.caption,
                    authorTitle: "إسم المصور",
                    authorName: removeUndefined(image.photographerName),
                    sourceName: removeUndefined(image.sourceName),
                    date: image.year + " - " + image.month + " - " + image.day,
                    entities: getEntitiesList(image.entities),
                    description: image.description,
                    direction: image.languageName === 'EN' | 'FR' ? 'ltr' : 'rtl',
                    fileName: image.fileName
                }
            })
        },

        setHasNoResults() {
            if (this.images.length == 0) {
                this.hasNoResults = true
            } else {
                this.hasNoResults = false
            }
        },

        setShowFilterChipsContainer() {
            delete this.filterChipsData['order']; 
            delete this.filterChipsData['pageNumber']; 
            delete this.filterChipsData['pageSize']; 
            delete this.filterChipsData['sortBy']; 

            if (this.filterChipsData) {
                if (Object.keys(this.filterChipsData).length === 0 && this.filterChipsData.constructor === Object) {
                    this.showFilterChipsContainer = false
                } else {
                    this.showFilterChipsContainer = true
                }
            } else {
                this.showFilterChipsContainer = false
            }
        },

        setActiveImage (image) {
            this.activeImage = image

            if (this.isImageBookmarked(image.id)) {
                this.isActiveImageBookmarked = true
            } else {
                this.isActiveImageBookmarked = false
            }

            this.$router.push({
                pathname: '/images',
                query: {
                    ...this.$route.query
                },
                hash: '#' + image.id
            }, undefined, { shallow: true})

            window.scrollTo(0,0);
        },

        isImageBookmarked(id) {
            return this.bookmarkedImages.filter(image => image.id === id).length > 0
        },

        hideFocusedImage() {
            this.$router.push({
                pathname: '/images',
                query: {
                    ...this.$route.query
                },
                hash: ''
            }, undefined, { shallow: true})

            this.activeImage = null
        },

        goToPageRouter(pageName) {
            this.$router.push({
                name: pageName,
                // params: {
                //     value: this.searchValue
                // },
                query: this.getBaseQuery
            })
        },

        goToPage(pageName) {
            // if (this.searchValue) {
                this.goToPageRouter(pageName)
            // } else {
            //     this.$router.push({
            //         name: 'home'
            //     })
            // }
        },

        handlePageChange(value) {
            this.currentPage = value
            var query = JSON.parse(JSON.stringify(this.getBaseQuery));
            query['pageNumber'] = value
            this.SET_BASE_QUERY(query)
            var currentPage = this.$route.name
            this.goToPage(currentPage)
            this.$router.go()
            window.scrollTo(0,0);
        },

        deleteFilterChip(type, filter) {
            this.setReloadId()
            var query = JSON.parse(JSON.stringify(this.getBaseQuery));

            if (typeof query[type] === 'string' || query[type] instanceof String) {
                delete query[type];
            } else if (Array.isArray(query[type])) {
                if (query[type].length == 1) {
                    delete query[type];
                }
                else {
                    const index = query[type].indexOf(filter);
                    if (index > -1) {
                        query[type].splice(index, 1);
                    }
                }
            }

            this.SET_BASE_QUERY(query)
            this.RESET_PAGE_NUMBER()
            
            var currentPage = this.$route.name
            this.goToPage(currentPage)
            this.$router.go()
            window.scrollTo(0,0);
        },

        async toggleBookmarkImage(id) {
            if (this.isLoggedIn) {
                var data = {
                    userId: this.user.id, 
                    imageId: id
                }

                this.isBookmarkLoading = true
                if (this.isImageBookmarked(id)) {
                    await this.unBookmarkImage(data)
                } else {
                    await this.bookmarkImage(data)
                }
                this.isBookmarkLoading = false
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        async bookmarkImage(data) {
            await bookmarkImage(data).then(
                response => {
                    this.bookmarkedImages.push({id: response.data.payload.imageId})
                    this.isActiveImageBookmarked = true
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        async unBookmarkImage(data) {
            await unBookmarkImage(data).then(
                () => {
                    removeByAttr(this.bookmarkedImages, 'id', data.imageId)
                    this.isActiveImageBookmarked = false
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        async downloadImage() {
            if (this.isLoggedIn) {
                var fileName = this.activeImage.fileName

                if (fileName) {
                    let url = config.apiUrl + '/entities/images/raw/' + fileName
                    window.open(url)
                } else {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        }
    }
}